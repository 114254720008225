import React from "react";
import {Helmet} from "react-helmet";
import styled from 'styled-components';
import Layout, {Headline} from '../../components/layout';
import bg from "../../assets/img/hero2.jpg";

const H2 = styled.h2`
  font-size: 22px;
  margin-top: 40px;
`;

export default function AboutUs() {
    return (
        <Layout
            bg={bg}
            hero={false}
        >
            <Helmet>
                <title>Privacy Policy</title>
                <meta name="description" content="Direct Buyers Privacy Policy"/>
            </Helmet>

            <div className={`container`}>
                <Headline><h1>Privacy Policy</h1></Headline>
                <div className={`row`}>
                    <div className="col-12">
                        <p>
                            DirectBuyers.com is committed to maintaining your confidence and trust, protecting your privacy
                            on our website. This Privacy Policy tells you about the information DirectBuyers.com collects
                            through the services available on our website www.Directbuyers.com and how we use that
                            information. By using this website, you are accepting the practices described in this Privacy
                            Policy and your visit and any dispute over privacy is subject to this Privacy Policy and our
                            Conditions of Use. The kinds of information we collect from you will vary, depending on how you
                            use our website.
                        </p>

                        <H2>CONDITIONS OF USE:</H2>
                        <p>
                            We gather certain types of information about the users on our site, and we feel you should fully
                            understand the terms and conditions surrounding our acquisition and use of that information.
                            This privacy statement discloses what information we gather, how we use it, and how you can
                            correct or update that information. Various types of information collected from our visitors
                            vary depending on how you use our website. Please be aware that it is common to browse our site
                            without submitting any personally identifiable information (“personal information”) at all, but
                            if you choose to take advantage of our services, you may be asked to provide information such as
                            your name, email address, phone number, and address, etc. For each visitor to our website our
                            server most commonly recognizes only the consumer's domain name, not the email address
                            associated. The domain name & email addresses of visitors to our website, those who post
                            messages to our blogs, and who communicate with us via email, as well as those who make postings
                            to our chat areas, aggregate information on what pages consumers access or visit, information
                            volunteered by the consumer, such as information submitted when filling out a form/inquiry,
                            survey information and/or site registrations, name and address, telephone number are also
                            collected. When you submit your request through one of our forms or chat conversations, your
                            information is then transmitted to our real estate professionals. Please note we have
                            appropriate security measures in place in our physical facilities to protect against the loss,
                            misuse or alteration of information that we have collected from you at our site.
                        </p>
                        <p>
                            By submitting your personal information on our website, you express your full consent for a real
                            estate professional to contact you either by phone, email, postal service or any other method of
                            communication. When DirectBuyers.com is provided with your contact info, including but not
                            limited to your postal address you may receive periodic mailings from us with information on new
                            products and services or upcoming events. If you do not wish to receive such mailings, or would
                            like to prevent your information from being used for purposes other than those for which you
                            originally intended please let us know by emailing us at info@directbuyers.com. In addition to
                            the information you choose to submit, our systems are designed to gather certain anonymous data
                            to help us better understand how our sites are being used and how we can improve them. This
                            automatically gathered data includes your computer’s IP or “Internet Protocol” address,
                            statistics about how visitors navigate through our sites, and information provided through the
                            use of “cookies”. Cookies are used on our website to record session information, such as data
                            that consumers input onto the site, record user-specific information on what pages users access
                            or visit, record past activity at a site in order to provide better service when visitors return
                            to our site, ensure that visitors are not repeatedly sent the same banner ads, customize web
                            page content based on visitors' browser type or other information that the visitor sends.
                        </p>
                        <p>
                            We reserve the right to make our customer email lists, telephone numbers and postal addresses
                            available to other reputable organizations whose products or services we think you might find
                            interesting. We use this information to improve the content of our website as well as to
                            customize the content of our site, used by us to contact consumers for marketing purposes,
                            shared with other reputable organizations to help them contact consumers for marketing purposes,
                            and may use customer information for new, unanticipated uses not previously disclosed in our
                            privacy notice. Consumer information may be disclosed when legally required to do so, at the
                            request of governmental authorities conducting an investigation, to verify or enforce compliance
                            with the policies governing our website and applicable laws or to protect against misuse or
                            unauthorized use of our website. Please note, we may at times be legally compelled to disclose
                            certain information by means such as subpoenas or court orders. If you do not want us to share
                            your information with other companies or organizations, please let us know by emailing us at
                            info@directbuyers.com.
                        </p>

                        <H2>CHANGES TO THIS PRIVACY POLICY</H2>
                        <p>
                            We reserve the right to update this Privacy Policy at any time to reflect changes in our
                            practices and services. We advise you to visit this privacy policy regularly to find out of any
                            changes in our policies regarding your privacy. If you are concerned about how your information
                            is used, we encourage you to review our privacy policy periodically. Upon request we offer the
                            ability to have inaccuracies corrected in contact information Consumers can have this
                            information corrected by emailing us at info@directbuyers.com
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
